<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
        <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
         <div class="mt-3 mb-4" v-if="windowWidth >= 992" style="padding:0% 2%">

   <div class="display-div mb-2 point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; max-width:300px;     padding: 0px 10px;"   v-if="windowWidth <= 1270 && windowWidth >= 770" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"  @click="copyRefCode($event)">
                                        <div >

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>
                                         <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>


                    <div class="display-div" style="justify-content:space-between; align-items:center" v-if="windowWidth > 769">

                        <div  style=" flex-basis:30%" :class="{'flex-22': windowWidth < 1614}" >
                           <div class="mb-2 point-cursor" @click="gotoHome">
             
                <img src="../../../assets/images/back2.png" class="mr-4" height="40">
           
          
            </div>


                                <!-- <i class="fa fa-chevron-down" ></i> -->
                            </div>

                                <div style="flex-basis:40%; display:flex; justify-content:center" v-if="windowWidth > 1270" :class="{'flex-36': windowWidth < 1614}">

                                    <div  style="max-width:300px">
                                    <div class="display-div point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"   @click="copyRefCode($event)">
                                        <div>

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>

                                           <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                               
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20" title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>
                                                </div>
                                            </div>
                                               <div style="flex-basis:30%; display:flex; justify-content:flex-end" :class="{'flex-38': windowWidth < 1614}">
                                            <div>
                                                <div style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center;  padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/bookIcon3.png"  height="20">

              </div>
                                                          <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current booking total</p>

                      <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedValue)}}
                    </h5>
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                      <div >
                                                <div class="ml-2" style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/info.png"  height="20">

              </div>
                                                        <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current accrued interest</p>

            <h5 class="text-right" style="color:#2FA8A8">
                      <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}
                    </h5>
                      <!-- <p class="text-right">retrieving value...</p> -->
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>
</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
      <div :class="{'container':windowWidth > 992}">
          <div class="book-home-cover mt-4">
            <div class="row">
      
              <div class="col-xl-6 col-lg-6 col-md-6  col-12 col-sm-12 offset-md-3  offset-xl-3 offset-lg-3 mt-2">
                <!-- Next page -->
                <div style="max-width: 441px;
    margin: 0 auto;">
                <div class="booking-card" style="background:#E4FAE1">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/bookinfo.png"
                      height="100"
                      style="    position: absolute;right: -11px;top: -10px;"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div style="display:flex; min-height:20px; align-items:center">
                      <img src="../../../assets/images/infocol.png" class="mr-2" height="30">
                      <h5 style="color:#828282">Info</h5>
                    </div>
                  </div>
                  <div style="color:#828282; min-height:30px;">
                    <p style="color:#4F4F4F">
                      Booking rate at <span style="font-weight:600"> {{bookingRate}}% interest per annum.</span>
                      </p>
                  </div>
                  <hr style="    margin: 0.2rem 0rem;">

                   <p>Interest on funds <span><b>less than {{bookingDuration}} days</b></span> of booking cannot be accessed by user.</p>
                </div>
              

                <div class="booking-card mt-3">
                  <div class="display-div" style="min-height:20px;align-items:center ">
                    <div style="flex-basis:75%">
                      <p style="color:#828282">Wallet Balance</p>
                      <p style="color:#58B46F"> <span>&#8358;</span>{{formatAmount(userBal)}}</p>
                    </div>
                    <div
                      style="display:flex; min-height:10px;align-items:center;"
                      @click="sendToWallet"
                      class="text-right"
                    >
                      <p
                        style="color:#0094FF;  border-left:0.5px solid #E0E0E0;"
                        class="mr-3 point-cursor"
                      >Top up</p>
                      <img src="../../../assets/images/rightarrow.png" class="mr-2" height="10">
                    </div>
                  </div>
                </div>

                <div class="booking-card mt-3">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/bookamount.png"
                      height="70"
                      style="    position: absolute;right: -11px;top: -10px;"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div style=" min-height:20px; flex-grow:0; align-items:center; flex-basis:50%">
                      <p style="color:#828282; font-size:18px"> Book Funds </p>
                      <div class="mt-2" style=" border-top:0.5px solid #E0E0E0;"></div>
                    </div>

                    <div class="text-right" style="position:relative;flex-basis:50%; ">
                      <img src="../../../assets/images/bookcol.png" height="30">
                    </div>
                  </div>
                  <div style="color:#828282;min-height:30px; position:relative">
                    <div style="display:flex">
                    <p style="flex-basis:40%">Enter an amount (<span>&#8358;</span>)</p>
                    <p style="flex-basis:60%; color:#4F4F4F; text-align:right"><span>&#8358;</span>{{formatAmount(bookingMinimumAmount)}} Minimum amount</p>


                    </div>
                    <input
                      type="text"
                      class="text-center"
                      style="min-width:100%;border:0.5px solid #E0E0E0;border-radius: 5px;min-height:70px;background: #FAFAFA; font-weight:600"
                      autofocus
                      @keydown.enter="openBookingModal"
                      @keyup="getFundValue"
                      :value="returnMinAmount"
                    >
                  </div>

                  <div>
                    <div
                      style="display:flex; min-height:50px;align-items:center; justify-content:center"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      class="point-cursor  mt-2" 
                       :class="{'fade-cont': returnInActivateBookingStatus }" 
                    >
             
                      <p :class="{'fade-cont': returnInActivateBookingStatus }"  style="color:#0094FF; font-size:16px" class="mr-3">Continue</p>
                      <img src="../../../assets/images/rightarrowb.png" class="mr-2" height="17" v-if="!returnInActivateBookingStatus">
                      <img src="../../../assets/images/fadeArrow.png" class="mr-2" height="17" v-if="returnInActivateBookingStatus">
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered"  style="max-width: 450px;" role="document">
            <div class="modal-content" style="background:#F5F5F5;">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Summary/Instruction</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="booking-card" style="background:#2FA8A8">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/bookcircle.png"
                      height="180"
                      style="position: absolute;right: -11px;
    top: -10px; z-index:0"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div>
                      <h6 style="color:#fff">I instruct reQuid to book...</h6>
                    </div>
                    <div class="text-right"></div>
                  </div>
                  <div
                    style="color:#828282; display:flex; align-items:center; min-height:60px; position:relativeposition:relative; border-bottom:0.5px solid #E0E0E0"
                  >
                    <img src="../../../assets/images/bookcol2.png" class="mr-3" height="30">
                    <p style="color:#fff; font-size:18px">₦{{formatAmount(bookAmmount)}} at {{bookingRate}}% per annum</p>
                  </div>
                  <div
                    style="color:#828282; display:flex; align-items:center; min-height:60px; position:relativeposition:relative;"
                  >
                    <img src="../../../assets/images/bookicon2.png" class="mr-3" height="30">
                    <div>
                      <p style="color:#FFE8B3; font-size:14px">Booking Fees</p>
                      <p style="color:#fff; font-size:18px">FREE</p>
                    </div>
                  </div>
                </div>
                <div class="booking-card mt-3">
                  <div style="position:relative"></div>

                  <div
                    style="color:#828282; display:flex; align-items:center; min-height:60px; position:relativeposition:relative;"
                  >
                    <div class="custom-control custom-checkbox" style="min-width:100%">
                      <input
                        type="checkbox"
                        @click="checkForTerms($event)"
                        class="custom-control-input"
                        id="customControlInline2"
                      >
                      <label class="custom-control-label" for="customControlInline2">
                        <p
                          style="color:#000; font-size:14px"
                          class="pt-1"
                        >I agree to the  <span style="text-decoration:underline" ><router-link to="/terms" target="_blank"  style="display:inline; padding:0rem; color:#000">terms and conditions </router-link></span>of the booking service</p>
                      </label>
                    </div>
                  </div>

                  <div style="border-top:0.5px solid #E0E0E0; width:30px" class="mt-2 mb-2"></div>
                  <div
                    style="color:#828282; display:flex; align-items:center; min-height:10px; position:relativeposition:relative;"
                  >
                    <p
                      style="color:#828282; font-size:14px"
                    >Please click the checkbox to agree and continue</p>
                  </div>
                  <hr>
                  <div
                   
                    style="display:flex; min-height:50px;align-items:center; justify-content:center"
                  
                  >
                    <p style="color:#0094FF; font-size:16px" data-dismiss="modal" aria-label="Close" @click="bookFunds"  :class="{'fade-cont':!termsPrivacy}"  class="mr-3 point-cursor"   v-if="this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null">Complete Booking</p>
                       <p style="color:#0094FF; font-size:16px" data-dismiss="modal" aria-label="Close" @click="gotoBvn"  :class="{'fade-cont':!termsPrivacy}"  class="mr-3 point-cursor" v-else> Submit bvn </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../../http/index";
import api from "../../../apis/index";
export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      minAmount: "",
      termsPrivacy: false,
      bookAmmount:'',
      inActivateBookingStatus: true,
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
    sendToAmountView() {
      this.$store.commit("auth/saveVestRouteVisited", "/vest/amount");
      this.$router.push("/vest/amount");
    },
    checkForTerms(event) {
      if (event.target.checked) {
        this.termsPrivacy = true;
      } else {
        this.termsPrivacy = false;
      }
    },

openBookingModal(){

if(!this.returnInActivateBookingStatus){

  $('#exampleModalCenter').modal('show');

}
else return;
},

    getFundValue(e) {
      let fundValue = e.target.value;
      e.target.value = parseInt(
        fundValue.replace(/\D/g, ""),
        10
      ).toLocaleString();

      this.minAmount = e.target.value;

      let inputValue = this.minAmount.toString().replace(/,/g, "");

      if (isNaN(this.minAmount.toString().replace(/,/g, ""))) {
        this.minAmount = "";
      }

      this.bookAmmount = inputValue
      this.$helpers.log(inputValue, "value of input");
      this.$helpers.log(this.bookingMinimumAmount, "booking amount");



if(parseFloat(inputValue) >= parseFloat(this.bookingMinimumAmount) && parseFloat(inputValue) <= parseFloat(this.userBal)){

    this.inActivateBookingStatus = false

}

else{
  this.inActivateBookingStatus = true
}

      // if (
      //   inputValue >= 1000 &&
      //   parseFloat(this.userBal) >= parseFloat(inputValue)
      // ) {
      //   this.amountIsNotEnough = false;
      //   this.showError = false;
      // } else if (
      //   inputValue >= 1000 &&
      //   parseFloat(this.userBal) <= parseFloat(inputValue)
      // ) {
      //   this.amountIsNotEnough = true;
      //   this.showError = true;
      // } else {
      //   this.amountIsNotEnough = true;
      // }
    },
  
    sendToWallet() {
      if(this.userProfileInfo.bvn !== '0' && this.userProfileInfo.bvn !== null){
  this.$router.push({
        path: "/wallet",
        query: { openFundWallet: true, fundAmount: this.amount }
      });
      }
      else{
        this.gotoBvn()
      }
    
    },
    gotoTerms() {
      this.$router.push({
        path: "/terms",
      });
    },

    gotoHome(){

      this.$router.push({path: "/book",});
    },

        bookFunds() {

            let bookData = {
     amount: this.minAmount.toString().replace(/,/g, "")
               
            }

       

            let vm = this
            vm.$store.commit("market/startPreloader");
           
            //   this.$helpers.log(amountData, 'getAmount')
            http.post(api.bookFunds, bookData)

                .then(response => {
                    vm.$store.commit("market/stopPreloader");

                      this.$router.push({
        path: "/book/success",
        query: { fundAmount: bookData.amount }
      });
                      vm.$helpers.log(response);

                })

                .catch(error => {
                    if (error.response) {

                        if (error.response.data.message !== "Unauthenticated.") {
                            vm.$store.commit("domElements/openNotificationMessage")

                            vm.$store.commit("domElements/saveNotificationMessage", error.response.data.message)
                                 vm.$helpers.log(error.response);
                                     vm.$store.commit("market/stopPreloader");
                        }
                    }
                    this.$store.commit("market/stopLoader");
                });

        },

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      bookingRate: state => state.market.bookingSettings.default_rate,
      bookingMinimumAmount: state => state.market.bookingSettings.minimum_amount,
      bookingDuration: state => state.market.bookingSettings.minimum_duration_days,
     
    }),
    returnMinAmount() {
      if (this.minAmount.toString().replace(/,/g, "") % 1 !== 0) {
        return this.minAmount.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      } else {
        return this.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    returnInActivateBookingStatus() {
     return this.inActivateBookingStatus
    },
  
  },
  mounted() {
    let heading = {
      main: this.tran.booking
    };
     this.$store.dispatch("market/getBookingHistory");
    this.$store.commit("market/savePageHeading", heading);
    this.$store.dispatch("market/getBookingSettings");
    this.$store.dispatch("market/getBookFundsSummary");
   this.$store.commit(
      "auth/saveBookRouteVisited",
      this.$route.path
    );
  },

 
};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {
  min-height: 40vh;
  display: flex;
  align-items: center;
  padding-bottom: 5%;
}
.fade-cont{

  color: #E0E0E0 !important;
  pointer-events: none !important;
}
</style>

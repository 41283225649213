<template>
<div>
    <bookingActivity />
</div>
</template>

<script>
import bookingActivity from '../../components/dashboard/booking/BookingActivity'
export default {
    components: {
        bookingActivity
    }
}
</script>

<style>
